/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, HStack, Radio, RadioGroup } from '@chakra-ui/react';
import {
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import React from 'react';
import { FaBars, FaRegQuestionCircle } from 'react-icons/fa';
import { FaTrashCan } from 'react-icons/fa6';
import Select from 'react-select';

import styles from '@/components/view-editor/styles.module.scss';
import { DEFAULT_ARRAY_START_INDEX } from '@/constants/defaults';
import { SORTING_ORDER } from '@/constants/ui';
import { MultiSortColumn, ViewLayoutListColumns } from '@/types/view.types';

type SortableItemsProps = {
  disableDelete: boolean;
  handleDelete: (value: ViewLayoutListColumns) => void;
  handleOrderChange: (selectedRule: MultiSortColumn, sortDirection: SORTING_ORDER) => void;
  handleUpdate: (selectedValue: MultiSortColumn, oldRuleValue: ViewLayoutListColumns) => void;
  index: number;
  rule: MultiSortColumn;
  sortByOptions: MultiSortColumn[];
};

const ViewLayoutSortableItem = (props: SortableItemsProps): React.JSX.Element  => {
  const { rule, sortByOptions, handleUpdate, handleOrderChange, handleDelete, index, disableDelete } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: rule.id });
  const [selectedRule, setSelectedRule] = React.useState(rule);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onChange = (newRule: any) => {
    // we need the old rule info to find the current position of the updated rule,
    // so the new rule has the same position
    const oldRuleValue = selectedRule.columnValue;
    handleUpdate(newRule, oldRuleValue);
    setSelectedRule(newRule);
  };

  const onOrderChange = (sortDirection: SORTING_ORDER) => {
    const updatedOption = {
      ...selectedRule,
      sortDirection,
    };
    handleOrderChange(updatedOption, sortDirection);
    setSelectedRule(updatedOption);
  };

  const isAssignmentOrPersonnel =
    selectedRule.columnValue === ViewLayoutListColumns.ASSIGNMENT ||
    selectedRule.columnValue === ViewLayoutListColumns.PERSONNEL;

  const CUSTOM_SORT_TOOLTIP = `Custom sort ordering must be set up on the
    ${selectedRule.columnValue === ViewLayoutListColumns.ASSIGNMENT ? 'Assignments' : 'Personnel'} tab within the View Manager.`;

  return (
    <Box ref={setNodeRef} style={style} className={classNames(styles.draggableContainer)}>
      <div className={classNames(styles.draggableRow)}>
        <FaBars key={rule.id} {...listeners} {...attributes} />
        {index === DEFAULT_ARRAY_START_INDEX ? 'Sort By' : 'then by'}
        <Select
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              width: 250,
            }),
          }}
          options={sortByOptions}
          getOptionLabel={(option) => option.columnValue}
          getOptionValue={(option) => option.columnValue}
          onChange={onChange}
          value={selectedRule}
        />
        <RadioGroup
          value={selectedRule.sortDirection}
          onChange={onOrderChange}
          className={classNames(styles.sortOrderRadioGroup)}
        >
          <HStack gap="6">
            <Radio value={SORTING_ORDER.ASC}>A to Z</Radio>
            <Radio value={SORTING_ORDER.DESC}>Z to A</Radio>
            {isAssignmentOrPersonnel && (
              <>
                <Radio value={SORTING_ORDER.CUSTOM}>
                  <div className={classNames(styles.customRadioGroupOption)}>
                    Custom
                    <FaRegQuestionCircle
                      className={classNames(styles.faQuestionIcon)}
                      title={CUSTOM_SORT_TOOLTIP}
                      color="gray"
                    />
                  </div>
                </Radio>
              </>
            )}
          </HStack>
        </RadioGroup>
        <Button
          isDisabled={disableDelete}
          rightIcon={<FaTrashCan />}
          variant={'ghost'}
          onClick={() => handleDelete(selectedRule.columnValue)}
        />
      </div>
    </Box>
  );
};

export default ViewLayoutSortableItem;
