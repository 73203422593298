// Use @reduxjs/toolkit/query/react as it provides a React-specific entry point
// that automatically generates hooks corresponding to the defined endpoints

/* eslint-disable camelcase */
import { HttpStatusCode } from 'axios';
import _ from 'lodash';
import { HttpMethods } from 'msw';

import { apiSlice } from '@/API/api.slice';
import { ContentTypes, Endpoints, HeaderKeys, RTKQueryTags } from '@/constants/api';
import { paginationDefault } from '@/constants/defaults';
import { ORDER_BY, SORTING_ORDER } from '@/constants/ui';
import { PaginationFilter, ResponseData } from '@/types/api.types';
import View, {
  PublicView,
  PublicViewDTO,
  ViewColoredItem,
  ViewFilter,
  ViewListItem,
  ViewLite,
  ViewLiteDTO,
  ViewTheme,
  ViewThemeData,
} from '@/types/view.types';
import { toSimpleDate } from '@/utils/dates';
import { serializeViewFilter } from '@/utils/transformers/serialization';
import { isDemandTallyId } from '@/utils/validators';

export interface ViewDTO extends View {
  created_at: string;
  created_by: string;
  filter_id: number;
  name: string;
  theme_id: number;
  updated_at: string;
  updated_by: string;
  public_id: string | null;
  view_id: number;
}

interface ViewAccess {
  hasAccess: boolean;
}

interface ViewListResponse extends PaginationFilter {
  Data: ViewDTO[];
}

interface PaginatedViewsList {
  pagination: PaginationFilter;
  viewsList: ViewListItem[];
}

export enum ViewOrderBy {
  None = 'None',
  ViewName = 'ViewName',
  LastModified = 'LastModified',
  PublicView = 'PublicView',
}

interface ViewsListParams {
  departmentList?: number[];
  filter?: string;
  isAscending?: boolean;
  orderBy?: ViewOrderBy;
  pagination?: Partial<PaginationFilter>;
  templateList?: number[];
  viewIdsList?: number[];
}

interface ViewsParams {
  departmentList?: number[];
  filter?: string;
  isAscending?: boolean;
  orderBy?: ViewOrderBy;
  templateList?: number[];
  viewIdsList?: number[];
}

export interface PublicViewsListItem {
  guid: string;
  viewId: number;
}

// Exclude explicitly required fields from ViewUpdate
type ViewUpdate = Partial<Omit<View, 'viewId' | 'filterId' | 'name' | 'themeId'>>;

export interface UpdateViewDTO extends ViewUpdate {
  accessibleBy: number[];
  filterId: number;
  name: string;
  themeId: number;
  viewId: number;
}

interface GenerateViewPublicURL {
  name: string;
  viewId: number;
}

export const viewsApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: [RTKQueryTags.ViewsList, RTKQueryTags.PublicViews, RTKQueryTags.View],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      createFilter: builder.mutation<ViewFilter, { filterData: ViewFilter; filterName: string }>({
        query: (createFilterData) => ({
          body: JSON.stringify({
            ...serializeViewFilter(createFilterData.filterData),
            name: createFilterData.filterName,
          }),
          headers: {
            [HeaderKeys.ContentType]: ContentTypes.ApplicationJSON,
          },
          method: HttpMethods.POST,
          url: Endpoints.ViewsFilter,
        }),
      }),
      createTheme: builder.mutation<ViewTheme, { themeData: ViewThemeData; themeName: string }>({
        query: (createThemeData) => ({
          body: JSON.stringify({
            data: JSON.stringify(createThemeData.themeData),
            name: createThemeData.themeName,
          }),
          headers: {
            [HeaderKeys.ContentType]: ContentTypes.ApplicationJSON,
          },
          method: HttpMethods.POST,
          url: Endpoints.ViewsTheme,
        }),
      }),
      createView: builder.mutation<ViewDTO, View>({
        invalidatesTags: [RTKQueryTags.ViewsList, RTKQueryTags.PublicViews],
        query: (createViewData) => ({
          body: JSON.stringify({
            // eslint-disable-next-line camelcase
            accessible_by: createViewData.accessibleBy,
            // eslint-disable-next-line camelcase
            filter_id: createViewData.filterId,
            name: createViewData.name,
            // eslint-disable-next-line camelcase
            theme_id: createViewData.themeId,
          }),
          headers: {
            [HeaderKeys.ContentType]: ContentTypes.ApplicationJSON,
          },
          method: HttpMethods.POST,
          url: Endpoints.VMViewsCreate,
        }),
      }),
      deletePublicViewUrl: builder.mutation<ResponseData | null, string>({
        invalidatesTags: [RTKQueryTags.PublicViews],
        query: (guid) => ({
          method: HttpMethods.DELETE,
          url: `${Endpoints.ViewsPublic}/${guid}`,
        }),
        transformResponse: (_response, meta) => {
          // Return true if the response status === 204 because the api does not return any response data when deleting
          if (meta?.response?.status === HttpStatusCode.NoContent) {
            return { deleted: true } as ResponseData;
          }
          return null;
        },
      }),
      deleteView: builder.mutation<ResponseData | null, number>({
        invalidatesTags: [RTKQueryTags.ViewsList, RTKQueryTags.PublicViews],
        query: (viewId) => ({
          method: HttpMethods.DELETE,
          url: `${Endpoints.Views}/${viewId}`,
        }),
        transformResponse: (_response, meta) => {
          // Return true if the response status === 204 because the api does not return any response data when deleting
          if (meta?.response?.status === HttpStatusCode.NoContent) {
            return { deleted: true } as ResponseData;
          }
          return null;
        },
      }),
      generatePublicViewUrl: builder.mutation<PublicView, GenerateViewPublicURL>({
        invalidatesTags: [RTKQueryTags.PublicViews],
        query: (viewInfo) => ({
          body: {
            name: viewInfo.name,
            view_id: viewInfo.viewId,
          },
          method: HttpMethods.POST,
          url: Endpoints.ViewsPublic,
        }),
        transformResponse: (response: PublicViewDTO) => {
          const transformed: Record<string, unknown> = {};

          for (const [k, v] of Object.entries(response)) {
            const camelCaseKey = _.camelCase(k);
            transformed[camelCaseKey] = v;
          }

          return transformed as unknown as PublicView;
        },
      }),
      getPublicViews: builder.query<PublicViewsListItem[], void>({
        providesTags: [RTKQueryTags.PublicViews],
        query: () => Endpoints.ViewsPublic,
        transformResponse: (response: PublicViewDTO[]) => {
          if (!response) return [];

          return response.map((view) => {
            return {
              guid: view.id,
              viewId: view.view_id,
            };
          });
        },
      }),
      getView: builder.query<View, number | undefined>({
        providesTags: [RTKQueryTags.View],
        query: (id) => `${Endpoints.Views}/${id}?isViewManager=true`,
        transformResponse: (response: ViewDTO) => {
          const apiReadyViewUpdate: Record<string, unknown> = {};

          for (const [k, v] of Object.entries(response)) {
            const camelCaseKey = _.camelCase(k);
            apiReadyViewUpdate[camelCaseKey] = v;
          }

          const viewResult = apiReadyViewUpdate as unknown as View;

          viewResult.filter.on_demandTallies = [];

          viewResult.filter.on_tallies = viewResult.filter.on_tallies.reduce((acc, cur) => {
            if (isDemandTallyId(cur.id as string)) {
              viewResult.filter.on_demandTallies.push(cur);
              return acc;
            }

            acc.push(cur);

            return acc;
          }, [] as ViewColoredItem[]);

          return viewResult;
        },
      }),
      getViewAccess: builder.query<ViewAccess, number | undefined>({
        query: (id) => `${Endpoints.VMViewAccess}/${id}`,
      }),
      getViews: builder.query<ViewLite[], ViewsParams>({
        query: ({
          departmentList = [],
          filter = '',
          isAscending = true,
          orderBy = ORDER_BY.ViewName,
          templateList = [],
          viewIdsList = [],
        }) => ({
          body: {
            DepartmentIds: departmentList,
            Filter: filter,
            IsAscending: isAscending,
            OrderBy: orderBy,
            TemplateIds: templateList,
            ViewIds: viewIdsList,
          },
          method: HttpMethods.POST,
          url: Endpoints.VMViews,
        }),
        transformResponse: (response: ViewLiteDTO[]) => {
          if (!response) return [];

          return response
            .filter(({ read_only }) => !read_only)
            .map((viewItem) => ({
              departments: viewItem.filter.on_departments,
              id: viewItem.view_id,
              name: viewItem.name,
              readOnly: viewItem.read_only,
              templates: viewItem.filter.on_templates,
            }));
        },
      }),
      getViewsList: builder.query<PaginatedViewsList, ViewsListParams>({
        providesTags: [RTKQueryTags.ViewsList],
        query: ({
          departmentList = [],
          filter = '',
          isAscending = true,
          orderBy = SORTING_ORDER.NONE,
          pagination = paginationDefault,
          templateList = [],
          viewIdsList = [],
        }) => ({
          body: {
            DepartmentIds: departmentList,
            Filter: filter,
            IsAscending: isAscending,
            OrderBy: orderBy,
            Pagination: pagination,
            TemplateIds: templateList,
            ViewIds: viewIdsList,
          },
          method: HttpMethods.POST,
          url: Endpoints.VMViewsList,
        }),
        transformResponse: (response: ViewListResponse) => {
          const { PageNumber, PageSize, TotalPages, TotalRecords } = response;

          const viewsData = response.Data.map((view) => {
            const departments = view.filter.on_departments ?? [];
            const updatedBy = view.updated_by;
            const updatedTimestamp = view.updated_at;
            const updatedTimestampCompact = toSimpleDate(view.updated_at);

            return {
              departments,
              filterId: view.filter_id,
              id: view.view_id,
              name: view.name,
              publicViewId: view.public_id,
              templates: view.filter.on_templates ?? [],
              themeId: view.theme_id,
              updatedBy,
              updatedTimestamp,
              updatedTimestampCompact,
            };
          });

          return {
            pagination: {
              // eslint-disable-next-line no-magic-numbers
              PageIndex: PageNumber - 1 < 0 ? 0 : PageNumber - 1,
              PageNumber,
              PageSize,
              TotalPages,
              TotalRecords,
            },
            viewsList: viewsData,
          };
        },
      }),
      giveSelfAccess: builder.mutation<ResponseData | null, number>({
        invalidatesTags: [RTKQueryTags.View],
        query: (viewId) => {
          return {
            method: HttpMethods.PUT,
            url: `${Endpoints.VMGiveSelfAccess}/${viewId}`,
          };
        },
        transformResponse: (_response, meta) => {
          // Return true if the response status === 204 because
          // the api does not return any response data when giving access
          if (meta?.response?.status === HttpStatusCode.NoContent) {
            return { gainedAccess: true } as ResponseData;
          }

          return null;
        },
      }),
      updateFilter: builder.mutation<ViewFilter, ViewFilter>({
        query: (filterUpdateObj) => {
          return {
            body: {
              ...serializeViewFilter(filterUpdateObj),
            },
            method: HttpMethods.PUT,
            url: `${Endpoints.ViewsFilter}/${filterUpdateObj.filter_id}`,
          };
        },
      }),
      updateTheme: builder.mutation<ViewTheme, ViewTheme>({
        query: (themeUpdateObj) => {
          const dereferencedThemeData = { ...themeUpdateObj };
          // eslint-disable-next-line camelcase
          const { data, theme_id } = dereferencedThemeData;

          return {
            body: {
              data: JSON.stringify(data),
              name: dereferencedThemeData.name,
            },
            method: HttpMethods.PUT,
            // eslint-disable-next-line camelcase
            url: `${Endpoints.ViewsTheme}/${theme_id}`,
          };
        },
      }),
      updateView: builder.mutation<void, UpdateViewDTO>({
        invalidatesTags: [RTKQueryTags.ViewsList, RTKQueryTags.PublicViews, RTKQueryTags.View],
        query: (viewUpdateObj) => {
          const deRefViewUpdateObject = { ...viewUpdateObj };
          deRefViewUpdateObject.accessibleBy = [...new Set(deRefViewUpdateObject.accessibleBy)];

          const apiReadyViewUpdate: Record<string, unknown> = {};

          for (const [k, v] of Object.entries(deRefViewUpdateObject)) {
            const snakeCaseKey = _.snakeCase(k);
            apiReadyViewUpdate[snakeCaseKey] = v;
          }

          return {
            body: {
              ...apiReadyViewUpdate,
            },
            method: HttpMethods.PUT,
            url: `${Endpoints.Views}/${deRefViewUpdateObject.viewId}`,
          };
        },
      }),
    }),
  });

export const {
  useCreateFilterMutation,
  useCreateThemeMutation,
  useCreateViewMutation,
  useDeleteViewMutation,
  useGetPublicViewsQuery,
  useGetViewQuery,
  useGetViewsListQuery,
  useGiveSelfAccessMutation,
  useLazyGetViewAccessQuery,
  useLazyGetViewsQuery,
  useGeneratePublicViewUrlMutation,
  useDeletePublicViewUrlMutation,
  useUpdateFilterMutation,
  useUpdateThemeMutation,
  useUpdateViewMutation,
} = viewsApi;
