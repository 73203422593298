/* eslint-disable camelcase*/
import { UserPassport } from '@/types/user.types';
import { ConfigHelper, ConfigKeys } from '@/utils/env';

enum API_ERRORS {
  BAD_REQUEST = 'bad_request',
  INVALID_CLIENT = 'invalid_client',
  INVALID_GRANT = 'invalid_grant',
  NOT_FOUND = 'not_found',
}

const AUTHORIZATION_PAGE_MESSAGE = 'This page requires authentication.';

const BASE_URL = ConfigHelper.getConfig(ConfigKeys.LB_API_HOST);

const CLIENT_ID = ConfigHelper.getConfig(ConfigKeys.CLIENT_ID);

export enum ELEMENT_DATA_TEST_IDS {
  AVAILABLE_TABLE_BODY = 'available-table-body',
  CANCEL_ACTION_BUTTON = 'cancel-action-button',
  CLONE_VIEW_BUTTON = 'clone-view',
  CLONE_VIEW_DIALOG = 'clone-view-dialog',
  CLONE_VIEW_DIALOG_CANCEL_BUTTON = 'clone-view-dialog-cancel-button',
  CLONE_VIEW_DIALOG_CLONE_AND_EDIT_BUTTON = 'clone-view-dialog-clone-and-edit-button',
  CLONE_VIEW_DIALOG_CLONE_BUTTON = 'clone-view-dialog-clone-button',
  CLONE_VIEW_DIALOG_INPUT = 'clone-view-dialog-input',
  COLOR_PICKER_BUTTON = 'color-picker-button',
  CONFIRM_ACTION_BUTTON = 'confirm-action-button',
  CONFIRM_ACTION_MODAL = 'confirm-action-modal',
  CONFIRM_DELETE_VIEW_NAME = 'confirm-delete-view-name',
  CONFIRMATION_DIALOG_CANCEL_BUTTON = 'confirmation-dialog-cancel-button',
  CONFIRMATION_DIALOG_CONFIRM_BUTTON = 'confirmation-dialog-confirm-button',
  CONTROLLED_SEARCH_INPUT = 'controlled-search-input',
  COPY_PUBLIC_URL_BUTTON = 'copy-public-url-button',
  CREATE_VIEW_BUTTON = 'create-view-button',
  CREATE_VIEW_EDITOR_DRAWER_HEADER = 'create-view-editor-drawer-header',
  DELETE_PUBLIC_URL_BUTTON = 'delete-public-url-button',
  DELETE_VIEW_BUTTON = 'delete-view-button',
  EDIT_VIEW_BUTTON = 'edit-view',
  FIRST_PAGE_BUTTON = 'first-page-button',
  GENERATE_PUBLIC_URL_BUTTON = 'generate-public-url-button',
  LAST_PAGE_BUTTON = 'last-page-button',
  LOADING_PAGE = 'loading-page',
  LOADING_VIEW_DIALOG = 'loading-view-dialog',
  MOVE_ITEMS_BOTTOM_BUTTON = 'move-items-bottom-button',
  MOVE_ITEMS_DOWN_BUTTON = 'move-items-down-button',
  MOVE_ITEMS_NEXT_PAGE_BUTTON = 'move-items-next-page-button',
  MOVE_ITEMS_PREV_PAGE_BUTTON = 'move-items-prev-page-button',
  MOVE_ITEMS_TOP_BUTTON = 'move-items-top-button',
  MOVE_ITEMS_UP_BUTTON = 'move-items-up-button',
  NEXT_PAGE_BUTTON = 'next-page-button',
  NO_VIEWS_FOUND = 'no-views-found',
  PAGE_SIZE_OF_FIFTY = 'page-size-of-fifty',
  PAGE_SIZE_OF_FORTY = 'page-size-of-forty',
  PAGE_SIZE_OF_TEN = 'page-size-of-ten',
  PAGE_SIZE_OF_THIRTY = 'page-size-of-thirty',
  PAGE_SIZE_OF_TWENTY = 'page-size-of-twenty',
  PAGE_SIZE_SELECT = 'page-size-select',
  PAGINATED_TABLE_CONTROLLED = 'paginated-table-controlled',
  PAGINATED_TABLE_CONTROLLED_ROW = 'paginated-table-controlled-row',
  PAGINATION_PAGE_COUNT = 'pagination-page-count',
  PERSONNEL_PAGE = 'personnel-page',
  PERSONNEL_PAGE_BUTTON = 'personnel-page-button',
  PREV_PAGE_BUTTON = 'prev-page-button',
  SELECTED_TABLE_BODY = 'selected-table-body',
  SORT_ICON_ASC = 'sort-icon-asc',
  SORT_ICON_DESC = 'sort-icon-desc',
  TABLE_HEADER_SEARCH = 'table-header-search',
  TABLE_HEADER_SELECT_ALL_BUTTON = 'table-header-select-all-button',
  TABLE_HEADER_ADD_ALL_BUTTON = 'table-header-add-all-button',
  TABLE_HEADER_CLEAR_SELECTION_BUTTON = 'table-header-clear-selection-button',
  TABLE_HEADER_ADD_SELECTED_BUTTON = 'table-header-add-selected-button',
  TABLE_HEADER_REMOVE_SELECTED_BUTTON = 'table-header-remove-selected-button',
  TABLE_HEADER_REMOVE_ALL_BUTTON = 'table-header-remove-all-button',
  UNAUTHORIZED_PAGE = 'unauthorized-page',
  VIEW_ACCESS_BUTTON = 'view-access-button',
  VIEW_ACCESS_DRAWER = 'view-access-drawer',
  VIEW_ACCESS_DRAWER_ALERT_DIALOG = 'view-access-drawer-alert-dialog',
  VIEW_ACCESS_DRAWER_ALERT_DIALOG_CANCEL_BUTTON = 'view-access-drawer-alert-dialog-cancel-button',
  VIEW_ACCESS_DRAWER_ALERT_DIALOG_DISCARD_BUTTON = 'view-access-drawer-alert-dialog-discard-button',
  VIEW_ACCESS_DRAWER_CANCEL_BUTTON = 'view-access-drawer-cancel-button',
  VIEW_ACCESS_DRAWER_SUBMIT_BUTTON = 'view-access-drawer-submit-button',
  VIEW_EDITOR_DRAWER_HEADER = 'view-editor-drawer-header',
  VIEWER_BUTTON = 'viewer-button',
  VIEWS_LIST = 'views-list',
  VIEWS_PAGE = 'views-page',
  VIEWS_PAGE_BUTTON = 'views-page-button',
}

// Added delay offset to prevent this from going negative
const TOKEN_EXPIRE_TIME_MS = 203;
const TOKEN_EXPIRE_TIME_VALIDATION_DELAY_MS = 3_500;

const BEARER_AUTH_RESPONSE_SSO = {
  access_token: 'super_secret_access_token_SSO',
  expires_in: TOKEN_EXPIRE_TIME_MS,
  refresh_token: 'mega_fresh_refresh_token_SSO',
  token_type: 'bearer',
};

const BEARER_AUTH_RESPONSE_REFRESH = {
  access_token: 'super_secret_access_token_REFRESH',
  expires_in: TOKEN_EXPIRE_TIME_MS,
  refresh_token: 'mega_fresh_refresh_token_REFRESH',
  token_type: 'bearer',
};

const BEARER_AUTH_RESPONSE_REFRESH_TOKEN_TEST = {
  access_token: 'super_secret_access_token_REFRESH_TEST',
  expires_in: TOKEN_EXPIRE_TIME_MS,
  refresh_token: 'ah_so_refreshing',
  token_type: 'bearer',
};

const BEARER_AUTH_RESPONSE_REFRESH_TOKEN_TEST_REFRESHED = {
  access_token: 'super_secret_access_token_REFRESH_TEST_REFRESHED',
  expires_in: TOKEN_EXPIRE_TIME_MS,
  refresh_token: 'oh_wow_I_am_just_really_so_totally_refresh_right_now',
  token_type: 'bearer',
};

const INVALID_REFRESH_TOKEN = 'THIS_REFRESH_TOKEN_IS_INVALID';

const VALID_REFRESH_TOKEN_FOR_REFRESH_TEST = 'THIS_REFRESH_TOKEN_CAN_BE_REFRESHED';

const REFRESH_TOKEN_FOR_REFRESH_TRIGGER_TEST_ONLY = 'THIS_REFRESH_TOKEN_IS_ONLY_FOR_REFRESH_TRIGGER_TEST';

const TEST_SSO_TOKEN = 'THIS_IS_A_TEST_SSO_TOKEN';

const DEFAULT_TEST_TIMEOUT = 10000;

const TEST_USER_PASSPORT: UserPassport = {
  applications: {
    analytics: true,
    maker: true,
    monitor: true,
    reports: true,
    view_design: true,
    viewer: true,
  },
  permissions: {
    can_modify_notifications_settings: false,
    customer_id: 9999999,
    data: [],
    emp_id: 999999999,
    is_admin: true,
    is_lbs_user: false,
    is_s1_client: false,
    is_super_admin: true,
    my_access_role_dept_map: { things: 'stuff' },
    // eslint-disable-next-line no-magic-numbers
    my_access_role_depts: [999999],
    // eslint-disable-next-line no-magic-numbers
    my_admin_depts: [999999],
    // eslint-disable-next-line no-magic-numbers
    my_depts: [9999],
    // eslint-disable-next-line no-magic-numbers
    my_home_depts: [2],
    // eslint-disable-next-line no-magic-numbers
    my_secondary_depts: [0],
    no_view_access_request: ['testing'],
    no_view_access_request_per_template: { andStuff: 'and things' },
    no_view_access_schedule: ['testing'],
    no_view_access_schedule_per_template: { andThings: 'and stuff' },
    user_access_role_id: 78,
    user_id: 999999,
    uses_intersections: false,
  },
  personnel: {
    accessible_views: [],
    compact_name: '',
    departments: [],
    display_name: '',
    emp_id: 0,
    expired: false,
    first_active_date: '',
    first_name: '',
    last_active_date: '',
    last_name: '',
    member_of_filters: [],
    notification_email: '',
    pager: '',
    phone_business: '',
    phone_cell: '',
    scheduled: false,
    user_id: 0,
  },
  user: {
    ShowDoximityFeatures: false,
    customer_id: 0,
    customer_name: '',
    database_type: '',
    email: '',
    emp_id: 0,
    feature_swops: false,
    is_admin: false,
    is_anesthesiago_user: false,
    is_lightningbolt_user: false,
    is_super_admin: false,
    locale: '',
    parameters: {},
    sm_client: '',
    sm_domain: '',
    time_display: '',
    tz: '',
    user_id: '',
    user_name: '',
  },
};

export default {
  API_ERRORS,
  AUTHORIZATION_PAGE_MESSAGE,
  BASE_URL,
  BEARER_AUTH_RESPONSE_REFRESH,
  BEARER_AUTH_RESPONSE_REFRESH_TOKEN_TEST,
  BEARER_AUTH_RESPONSE_REFRESH_TOKEN_TEST_REFRESHED,
  BEARER_AUTH_RESPONSE_SSO,
  CLIENT_ID,
  DEFAULT_TEST_TIMEOUT,
  ELEMENT_DATA_TEST_IDS,
  INVALID_REFRESH_TOKEN,
  REFRESH_TOKEN_FOR_REFRESH_TRIGGER_TEST_ONLY,
  TEST_SSO_TOKEN,
  TEST_USER_PASSPORT,
  TOKEN_EXPIRE_TIME_MS,
  TOKEN_EXPIRE_TIME_VALIDATION_DELAY_MS,
  VALID_REFRESH_TOKEN_FOR_REFRESH_TEST,
};
