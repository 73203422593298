/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Heading } from '@chakra-ui/react';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import classNames from 'classnames';
import React from 'react';
import { FaPlus } from 'react-icons/fa6';

import styles from '@/components/view-editor/styles.module.scss';
import ViewLayoutSortableItem from '@/components/view-editor/ViewLayoutSortableItem';
import { EMPTY_ARRAY_LENGTH, NUMERICAL_ONE, NUMERICAL_ZERO } from '@/constants/defaults';
import { SORTING_ORDER } from '@/constants/ui';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setViewEditorDraftMultiColumnSort } from '@/store/slices/viewEditor.slice';
import { MultiSortColumn, ViewLayoutListColumns } from '@/types/view.types';

const AdvancedSorting = (): React.JSX.Element => {
  const { viewDraft: view } = useAppSelector((state) => state.viewEditor);

  const { theme } = view;

  const dispatch = useAppDispatch();

  // we only have the selected columns name in the store,
  // so we use that info to create a more 'structured' object to identify an option
  const convertToSelectOptions = (columns: ViewLayoutListColumns[]) => {
    return columns.map((column) => ({
      columnValue: column,
      id: Object.values(ViewLayoutListColumns).indexOf(column) + NUMERICAL_ONE,
      sortDirection: SORTING_ORDER.ASC,
    }));
  };

  const selectedPreviewColumns = React.useMemo(
    () => (theme.data.listColumns ? convertToSelectOptions(theme.data.listColumns) : []),
    [theme.data.listColumns],
  );
  const selectedColumnsLength = selectedPreviewColumns.length;

  const [firstRule] = selectedPreviewColumns;
  const initialRulesList = theme.data.multiSortColumns?.length ? theme.data.multiSortColumns : [firstRule];

  const [rulesList, setRulesList] = React.useState(initialRulesList);

  const getInitialSortByOptions = React.useMemo(
    () => selectedPreviewColumns.filter((option) => rulesList.every((rule) => rule.columnValue !== option.columnValue)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [canAddMoreRules, setCanAddMoreRules] = React.useState(true);
  const [sortByOptions, setSortByOptions] = React.useState(getInitialSortByOptions);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  React.useEffect(() => {
    dispatch(setViewEditorDraftMultiColumnSort(rulesList));
    if (selectedColumnsLength === rulesList.length) {
      setCanAddMoreRules(false);
    }
  }, [dispatch, rulesList, selectedColumnsLength]);

  const getUnselectedOptions = React.useCallback(
    (newRules: MultiSortColumn[]) =>
      selectedPreviewColumns.filter((option) => newRules.every((rule) => rule.columnValue !== option.columnValue)),
    [selectedPreviewColumns],
  );

  const updateSortByOptions = (newRules: MultiSortColumn[]) => {
    setSortByOptions(getUnselectedOptions(newRules));
  };

  const handleDelete = (value: ViewLayoutListColumns) => {
    const filteredRules = rulesList.filter((rule: MultiSortColumn) => rule.columnValue !== value);
    setRulesList(filteredRules);
    setCanAddMoreRules(true);
    updateSortByOptions(filteredRules);
  };

  const handleOrderChange = (updatedRule: MultiSortColumn) => {
    const updatedRules = rulesList.map((rule) => {
      return rule.columnValue === updatedRule.columnValue ? updatedRule : rule;
    });
    setRulesList(updatedRules);
  };

  const handleUpdate = (updatedRule: MultiSortColumn, oldRuleValue: ViewLayoutListColumns) => {
    const index = rulesList.findIndex((r) => r.columnValue === oldRuleValue);
    const updatedRules = rulesList.map((rule, i) => {
      return i === index ? updatedRule : rule;
    });
    setRulesList(updatedRules);
    updateSortByOptions(updatedRules);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = (event: { active: any; over: any }) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setRulesList((items) => {
        const oldIndex = items.findIndex((rule: { id: any }) => rule.id === active.id);
        const newIndex = items.findIndex((rule: { id: any }) => rule.id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const addEmptyRule = () => {
    const unSelectedOptions = getUnselectedOptions(rulesList);
    // there are still unused options in the selected columns
    if (unSelectedOptions.length > EMPTY_ARRAY_LENGTH) {
      const newRule = {
        ...unSelectedOptions[NUMERICAL_ZERO],
        sortDirection: SORTING_ORDER.ASC,
      };
      const newRules = [...rulesList, newRule];
      setRulesList(newRules);
      // disable adding more after adding the last one
      if (selectedColumnsLength === newRules.length) {
        setCanAddMoreRules(false);
        setSortByOptions([]);
      } else {
        updateSortByOptions(newRules);
      }
    }
  };

  const disableDelete = rulesList.length === NUMERICAL_ONE;

  return (
    <Box className={classNames(styles.rulesListContainer)}>
      <Heading className={classNames(styles.rulesListHeader)}>Advanced Sorting Rules</Heading>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext items={rulesList} strategy={verticalListSortingStrategy}>
          {rulesList?.map((rule, i) => (
            <ViewLayoutSortableItem
              index={i}
              key={rule.id}
              rule={rule}
              handleUpdate={handleUpdate}
              handleOrderChange={handleOrderChange}
              handleDelete={handleDelete}
              sortByOptions={sortByOptions}
              disableDelete={disableDelete}
            />
          ))}
        </SortableContext>
      </DndContext>
      <Button
        isDisabled={!canAddMoreRules}
        leftIcon={<FaPlus />}
        onClick={addEmptyRule}
        className={classNames(styles.addRuleButton)}
      >
        Add another sort column
      </Button>
    </Box>
  );
};

export default AdvancedSorting;
